<template>
  <div>
    <div v-if="indexStore.debugAds" class="w-full h-full bg-red text-white p-8">
      {{ id }}
    </div>
    <div
      v-else-if="fullWidth"
      class="w-full bg-black/5"
      :data-ad-id="id"
      :style="{
        minHeight: minHeight ? minHeight + 'px' : '',
      }"
    >
      <div class="flex justify-center" :id="id"></div>
    </div>
    <div
      v-else-if="tempFullWith"
      class="w-full"
      :data-ad-id="id"
      :id="id"
      :style="{
        minHeight: minHeight ? minHeight + 'px' : '',
      }"
    ></div>
    <div
      v-else
      :data-ad-id="id"
      :id="id"
      :style="{
        minHeight: minHeight ? minHeight + 'px' : '',
      }"
    ></div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  id: string
  fullWidth?: boolean
  minHeight?: number
  tempFullWith?: boolean
}>()
const indexStore = useIndexStore()

useHead({
  script: [
    {
      innerHTML: `
  {
  let idToInject = {
        cmd: "injectNewAds",
        args: "${props.id}"
      };
      preConcept.queue.push(idToInject);
  }
  `,
      tagPosition: 'bodyClose',
    },
  ],
})

onUnmounted(() => {
  /*
This is a workaround to remove the ad label when navigating away from the page.
The external ad script runs after all DOM is loaded, so the label can be visible 
for some seconds on the page after page navigation, and this is not a good user experience.
Note: Our code for handling the ad label is in the app.vue file
*/
  if (true && import.meta.client) {
    const adsLabel = document.querySelector(`#ad-label-${props.id}`)
    if (adsLabel) {
      adsLabel.remove()
    }
  }
})
</script>
